import './App.css';

// Importing pages
import Home from './pages/formation'; // Home
import About from './pages/certibase.js'; // Certi-Base
import Course from './pages/consulting'; // Consulting
import Team from './pages/ressources'; // Ressources
import Testimonial from './pages/Testimonial';
import Error404 from './pages/Error404';
import Catalogue from './pages/catalogue'; // Catalogue page

// Importing Formation pages
import Leadership from './pages/formationpages/leadetshipetstrategie'; // Leadership et Stratégie
import LeadershipEtStrategieDetails from './pages/formationpages/LeadershipEtStrategieDetails'; // Detailed page for Leadership et Stratégie
import LeanManufacturing from './pages/formationpages/leanmanifacturing'; // Lean Manufacturing
import LeanSixSigma from './pages/formationpages/leansixsigma'; // Lean 6 Sigma
import MethodeQualite from './pages/formationpages/methodedelaqualite'; // Méthode de la Qualité
import InnovationConception from './pages/formationpages/innovationetconception'; // Innovation et Conception
import GestionProjets from './pages/formationpages/gestiondesprojets'; // Gestion des Projets
import Environnement from './pages/formationpages/environnement'; // Environnement
import SanteSecurite from './pages/formationpages/santeetsecuriteautravil'; // Santé et Sécurité au Travail
import ResponsabiliteSocietale from './pages/formationpages/responsabilitesocietale'; // Responsabilité Sociétale
import SécuritéAlimentaire from './pages/formationpages/securitéalimentaire';
import LeanSixSigmaPage from './pages/LeanSixSigmaPage';
import BlackBeltPage from './pages/BlackBeltPage'; 
import ProjectManagementIntroductionPage from './pages/ProjectManagementIntroductionPage'; // Import the page
import DesignThinkingDetails from './pages/DesignThinkingDetails';
import SPCControlChartsPage from './pages/SPCControlChartsPage';  
// Import LeanManufacturingPage at the top of App.js
import LeanManufacturingPage from './pages/LeanManufacturingPage';


// Importing Ressources pages
import FAQ from './pages/ressourcespages/faq.js'; // FAQ
import ProcessusCertification from './pages/ressourcespages/processusdecertification.js'; // Processus de Certification
import Financement from './pages/ressourcespages/financement.js'; // Financement
import Galerie from './pages/ressourcespages/galerie.js'; 

// Importing SearchCertification and TrainingDetail pages
import SearchCertification from './pages/SearchCertification'; // SearchCertification
import TrainingDetail from './pages/formationpages/TrainingDetail'; // TrainingDetail

// Importing EventList and EventDetail pages
import EventList from './pages/eventlist.js'; // Event List (Newly added)
import EventDetail from './pages/eventdetails'; // Event Detail (Newly added)
// Importing React Router
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Importing ContactForm component
import ContactForm from './components/ContactForm'; // ContactForm

function App() {
  return (
    <Router>
      <Routes>
        {/* Public Pages */}

        <Route path="/" element={<Home />} /> {/* Home page */}
        <Route path="/certibase" element={<About />} />
        <Route path="/consulting" element={<Course />} />
        <Route path="/evenements" element={<EventList />} /> {/* Event List Page */}
        <Route path="/event/:id" element={<EventDetail />} /> {/* Event Detail Page */}
        <Route path="/team" element={<Team />} />
        <Route path="/testimonial" element={<Testimonial />} />
        <Route path="/404" element={<Error404 />} />
        <Route path="/catalogue" element={<Catalogue />} /> {/* Catalogue page */}
        {/* SearchCertification */}
        <Route path="/search-certification" element={<SearchCertification />} />

        {/* Formation Pages */}
        {/* New route for the PDF list */}

        <Route path="/formation/leadership-strategie" element={<Leadership />} />
        <Route path="/formation/leadership-strategie-details" element={<LeadershipEtStrategieDetails />} />
        <Route path="/formation/leadership-strategie/details" element={<LeadershipEtStrategieDetails />} />
        <Route path="/formation/lean-manufacturing" element={<LeanManufacturing />} />
        <Route path="/formation/lean-6sigma" element={<LeanSixSigma />} />
        <Route path="/formation/methode-qualite" element={<MethodeQualite />} />
        <Route path="/formation/innovation-conception" element={<InnovationConception />} />
        <Route path="/formation/gestion-projets" element={<GestionProjets />} />
        <Route path="/formation/environnement" element={<Environnement />} />
        <Route path="/formation/sante-securite" element={<SanteSecurite />} />
        <Route path="/formation/securite-alimentaire" element={<SécuritéAlimentaire/>}/>
        <Route path="/formation/responsabilite-societale" element={<ResponsabiliteSocietale />} />
        <Route path="pages/lean-six-sigma-green-belt" element={<LeanSixSigmaPage/>} />
        <Route path="pages/black-belt" element={<BlackBeltPage />}/>
        <Route path="pages/project-management-introduction" element={<ProjectManagementIntroductionPage />} />
        <Route path="pages/lean-manufacturing" element={<LeanManufacturingPage />} />  
        <Route path="pages/design-thinking" element={<DesignThinkingDetails/>} /> 
        <Route path="pages/SPCControlCharts" element={<SPCControlChartsPage/>} /> 
        {/* Ressources Pages */}
        <Route path="/ressources/faq" element={<FAQ />} />
        <Route path="/ressources/processus-certification" element={<ProcessusCertification />} />
        <Route path="/ressources/financement" element={<Financement />} />
        <Route path="/ressources/galerie-photos" element={<Galerie />} />

        {/* Detailed Training Page */}
        <Route path="/training/:id" element={<TrainingDetail />} />
        <Route path="/formation/:courseId/training/:id" element={<TrainingDetail />} />
        <Route path="/training/:name" component={TrainingDetail} />

        {/* Contact Page (Contact Form) */}
        <Route path="/contact" element={<ContactForm />} />

        {/* Catch-all route for undefined paths */}
        <Route path="*" element={<Error404 />} />
      </Routes>
    </Router>
  );
}

export default App;
