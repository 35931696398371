import React from 'react';
import Header from '../components/Header.js';
import Footer from '../components/Footer.js';
import SearchCertification from './SearchCertification.js'; // Import the SearchCertification component

const About = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
            <Header page="certibase" />
            <div style={{ flex: '1' }}> {/* This allows the content area to expand */}
                <SearchCertification />
            </div>
            <Footer />
        </div>
    );
}

export default About;
