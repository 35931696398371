import React, { Component } from 'react';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import marquardt from '../Images/Logos Clients/marquardt.jpg';
import safran from '../Images/Logos Clients/safran.jpg';
import roseblanche from '../Images/Logos Clients/roseblanche.jpg';
import sartorius from '../Images/Logos Clients/sartorius.jpg';
import coficab from '../Images/Logos Clients/coficab.jpg';
import draexlmaier from '../Images/Logos Clients/draexlmaier.jpg';
import visteon from '../Images/Logos Clients/visteon.jpg';
import sagemcom from '../Images/Logos Clients/sagemcom.jpg';
import sfbt from '../Images/Logos Clients/sfbt.jpg';
import lacroix from '../Images/Logos Clients/lacroix.jpg';
import steg from '../Images/Logos Clients/steg.jpg';
import sia from '../Images/Logos Clients/sia-am.jpg'; 
import actia from '../Images/Logos Clients/actia.jpg';
import bic from '../Images/Logos Clients/bic.jpg';
import fuba from '../Images/Logos Clients/fuba.jpg';
import ttei from '../Images/Logos Clients/ttei.jpg';
import mersen from '../Images/Logos Clients/mersen.jpg';
import delice from '../Images/Logos Clients/delice.jpg';
import sopal from '../Images/Logos Clients/sopal.jpg';
import prysmian from '../Images/Logos Clients/prysmian.jpg';
import sofima from '../Images/Logos Clients/sofima.jpg';
import teleperformance from '../Images/Logos Clients/teleperformance.jpg';
import assad from '../Images/Logos Clients/assad.jpg';
import sit from '../Images/Logos Clients/sit.jpg';
import nexans from '../Images/Logos Clients/nexans.jpg';
import casco from '../Images/Logos Clients/casco.jpg';
import caveo from '../Images/Logos Clients/caveo.jpg';
import apem from '../Images/Logos Clients/apem.jpg';
import wkw from '../Images/Logos Clients/wkw.jpg';
import teriak from '../Images/Logos Clients/teriak.jpg';
import sebn from '../Images/Logos Clients/sebn.jpg';
import mpbs from '../Images/Logos Clients/mpbs.jpg';
import almaaden from '../Images/Logos Clients/almaaden.jpg';
import magriplast from '../Images/Logos Clients/magriplast.jpg';
import saida from '../Images/Logos Clients/saida.jpg';
import snbg from '../Images/Logos Clients/snbg.jpg';
import scg from '../Images/Logos Clients/scg.jpg';
import epidor from '../Images/Logos Clients/epidor.jpg';
import tunisiecables from '../Images/Logos Clients/tunisiecables.jpg';
import tpm from '../Images/Logos Clients/tpm.jpg';
import upergy from '../Images/Logos Clients/upergy.jpg';
import luxor from '../Images/Logos Clients/luxor.jpg';
import amenSante from '../Images/Logos Clients/amen-sante.jpg';
import allCircuits from '../Images/Logos Clients/all-circuits.jpg';
import apave from '../Images/Logos Clients/apave.jpeg';
import ennakl from '../Images/Logos Clients/ennakl.jpg';
import aramex from '../Images/Logos Clients/aramex.jpg';
import kone from '../Images/Logos Clients/kone.jpg';
import parenin from '../Images/Logos Clients/parenin.jpg';
import advts from '../Images/Logos Clients/advts.jpg'
import './TestimonialSlider.css';
import { Link } from 'react-router-dom';

const testimonials = [
  { "name": "Marquardt", "img": marquardt, "url": "https://www.marquardt.com/us/" },
  { "name": "Safran", "img": safran, "url": "https://www.safran-group.com/" },
  { "name": "Rose Blanche", "img": roseblanche, "url": "https://www.rose-blanche.com/en/home/" },
  { "name": "Sartorius", "img": sartorius, "url": "https://www.sartorius.com/en" },
  { "name": "Coficab", "img": coficab, "url": "https://www.coficab.com/" },
  { "name": "Draexlmaier", "img": draexlmaier, "url": "https://www.draexlmaier.com/en/" },
  { "name": "Visteon", "img": visteon, "url": "https://www.visteon.com/" },
  { "name": "Sagemcom", "img": sagemcom, "url": "https://sagemcom.com/en" },
  { "name": "Groupe SFBT", "img": sfbt, "url": "https://www.groupe-sfbt.com/" },
  { "name": "Lacroix Electronics", "img": lacroix, "url": "https://www.lacroix-electronics.com/" },
  { "name": "STEG", "img": steg, "url": "https://steg.com.tn/fr/index.html" },
  { "name": "SIA", "img": sia, "url": "https://www.sia-am.com/" },
  { "name": "Actia", "img": actia, "url": "https://www.actia.com/en/home/" },
  { "name": "BIC", "img": bic, "url": "https://corporate.bic.com/en-US" },
  { "name": "Fuba", "img": fuba, "url": "https://onetech-group.com/" },
  { "name": "TTEI", "img": ttei, "url": "https://onetech-group.com/" },
  {"name": "Tunisie Cables" , "img" : tunisiecables, "url":"https://www.tunisie-cables.com/"},
  { "name": "Mersen", "img": mersen, "url": "https://www.mersen.com/en" },
  { "name": "Delice", "img": delice, "url": "https://www.delice.tn/en/" },
  { "name": "Sopal", "img": sopal, "url": "https://www.sopal.com/" },
  { "name": "Prysmian", "img": prysmian, "url": "https://www.prysmian.com/en" },
  { "name": "Sofima", "img": sofima, "url": "https://www.ufifilters.com/" },
  { "name": "Teleperformance", "img": teleperformance, "url": "https://www.teleperformance.com/" },
  { "name": "Assad", "img": assad, "url": "https://assadpower.com.tn/" },
  { "name": "SIT Group", "img": sit, "url": "https://www.sitgroup.it/en/" },
  { "name": "Nexans", "img": nexans, "url": "https://www.nexans.com/" },
  { "name": "Casco", "img": casco, "url": "https://www.cascoauto.com/" },
  { "name": "Caveo", "img": caveo, "url": "http://www.caveoautomotive.com/" },
  { "name": "Apem", "img": apem, "url": "https://www.apem.com/idec-apem/en_IN" },
  { "name": "WKW", "img": wkw, "url": "https://www.wkw-group.com/en/" },
  { "name": "Teriak", "img": teriak, "url": "https://teriak.com/Fr/" },
  { "name": "SEBN", "img": sebn, "url": "https://www.sebn.com/en/" },
  { "name": "MPBS", "img": mpbs, "url": "https://mpbs.com.tn/en/" },
  { "name": "Al Maaden", "img": almaaden, "url": "https://utic.tn/societes/al-maaden/" },
  { "name": "Magriplast", "img": magriplast, "url": "https://utic.tn/en/societes/maghreb-industries-plastiques/" },
  { "name": "Saida", "img": saida, "url": "http://www.saida-group.com/" },
  { "name": "SNBG", "img": snbg, "url":""},
  { "name": "SCG", "img": scg, "url": "https://cimentsdegabes.com.tn/" },
  { "name": "Epidor", "img": epidor, "url": "" },
  {"name": "Advancia Teleservices", "img": advts, "url": "https://www.advancia-teleservices.com/en/"},
  {"name": "Mecachrome", "img": tpm, "url": "https://www.mecachrome.com/en/"},
    {"name": "Upergy", "img": upergy, "url": "https://www.upergy.com/"},
    {"name": "Luxor", "img": luxor, "url": "https://luxor.com.tn/en/"},
    {"name": "Amen Santé", "img": amenSante, "url": "https://www.amensante.com/Fr/"},
    {"name": "All Circuits", "img": allCircuits, "url": "https://www.allcircuits.com/"},
    {"name": "Apave", "img": apave, "url": "https://www.apave.com/en"},
    {"name": "Ennakl", "img": ennakl, "url": "https://www.ennakl.com/"},
    {"name": "Aramex", "img": aramex, "url": "https://www.aramex.com/tn/en"},
    {"name": "KONE", "img": kone, "url": "https://www.kone.com/en/"},
    
    {"name": "Parenin", "img": parenin, "url": "http://www.parenin.com.tn/"}
];

class TestimonialSlider extends Component {
  render() {
    // Group testimonials into chunks of 12 (6 per row, 2 rows per slide)
    const groupedTestimonials = [];
    for (let i = 0; i < testimonials.length; i += 12) {
      groupedTestimonials.push(testimonials.slice(i, i + 12));
    }

    return (
      <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
          <div className="text-center">
            <h1 className="mb-5">Nos clients</h1>
          </div>
          <OwlCarousel
            className="owl-theme"
            loop
            margin={10}
            nav
            responsive={{
              0: { items: 1 },
              600: { items: 1 },
              1000: { items: 1 },
            }}
            autoplay={true}
            autoplayTimeout={3000}
            autoplaySpeed={900}
            autoplayHoverPause={true}
          >
            {groupedTestimonials.map((group, index) => (
              <div key={index} className="testimonial-slide">
                {/* First Row of 6 Logos */}
                <div className="testimonial-row">
                  {group.slice(0, 6).map((testimonial, idx) => (
                    <div key={idx} className="testimonial-item">
                      {/* Check if URL is '/404' and redirect to Error page */}
                      {testimonial.url && (testimonial.url === '/404' || testimonial.url === '/pages/Error404') ? (
                        <Link to="/error">
                          <img
                            src={testimonial.img}
                            alt={testimonial.name}
                            className="testimonial-logo"
                          />
                        </Link>
                      ) : (
                        <Link to={testimonial.url}>
                          <img
                            src={testimonial.img}
                            alt={testimonial.name}
                            className="testimonial-logo"
                          />
                        </Link>
                      )}
                    </div>
                  ))}
                </div>

                {/* Second Row of 6 Logos */}
                <div className="testimonial-row">
                  {group.slice(6, 12).map((testimonial, idx) => (
                    <div key={idx} className="testimonial-item">
                      {/* Check if URL is '/404' and redirect to Error page */}
                      {testimonial.url && (testimonial.url === '/404' || testimonial.url === '/pages/Error404') ? (
                        <Link to="/error">
                          <img
                            src={testimonial.img}
                            alt={testimonial.name}
                            className="testimonial-logo"
                          />
                        </Link>
                      ) : (
                        <Link to={testimonial.url}>
                          <img
                            src={testimonial.img}
                            alt={testimonial.name}
                            className="testimonial-logo"
                          />
                        </Link>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </OwlCarousel>
        </div>
      </div>
    );
  }
}

export default TestimonialSlider;