import React, { Component } from 'react';
import course1 from '../Images/lss-gb.jpg';
import course2 from '../Images/lss-bb.jpg';
import course3 from '../Images/lean-manufacturing.jpg';
import course4 from '../Images/design-thinking .jpg';
import course5 from '../Images/agile-scrum.jpg';
import course6 from '../Images/spc.jpg';
import './coursesection.css';

const courses = [
    {
        "titre": "Lean Six Sigma Green Belt",
        "img": course1,
        "link": "pages/lean-six-sigma-green-belt", 
    },
    {
        "titre": "Passage de Green Belt à Black Belt",
        "img": course2,
        "link": "pages/black-belt", // Link to Lean Six Sigma Green Belt to Black Belt page
    },
    {
        "titre": "Lean Manufacturing",
        "img": course3,
        "link": "pages/lean-manufacturing", // Link to Lean Manufacturing page
    },
    {
        "titre": "Gestion de projet agile Méthode Scrum",
        "img": course5,
        "link": "pages/project-management-introduction", // Link to Lean Six Sigma Green Belt page
    },
    {
        "titre": "Design Thinking",
        "img": course4,
        "link": "pages/design-thinking", // Link to Lean Six Sigma Green Belt page
    },
    {
        "titre": "Statistical Process Control",
        "img": course6,
        "link": "pages/SPCControlCharts", // Link to Lean Six Sigma Green Belt page
    },
];

class CourseSection extends Component {
    render() {
        return (
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                        <h1 className="mb-5">Nos formations les plus populaires</h1>
                    </div>
                    <div className="row g-4 justify-content-center">
                        {courses.map((c, index) => (
                            <div key={index} className="col-md-4">
                                <div className="course-item">
                                    <img src={c.img} alt={c.titre} className="img-fluid" />
                                    <h4>{c.titre}</h4>
                                    <div className="btn-container">
                                        {/* Link to the respective course page */}
                                        <a href={c.link} className="btn btn-primary">En savoir plus</a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}

export default CourseSection;
