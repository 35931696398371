
import React, { useState } from 'react';
import './securitéalimentaire.css'; // Import the CSS file
import Header from '../../components/Header'; // Import the Header component
import Footer from '../../components/Footer'; // Import the Footer component

// Sample data for the trainings
const trainings = [
    {
        id: 'sustainable-practices',
        title: 'Exigences de la Norme ISO 22000:2018',
        details: [
            { 
                detail: "Description", 
                description: "Découvrez comment développer un système de management de la sécurité des denrées alimentaires efficace et efficient conforme à la norme ISO 22000:2018."
            },
            { 
                detail: "Objectives", 
                description: (<p> • Se familiariser avec les concepts et principes fondamentaux de la norme ISO 22000<br/> • Comprendre les exigences de la norme ISO 22000:2018 et être en mesure de les appliquer aux processus de son entreprise <br/> •Connaitre les méthodes et les outils nécessaires pour la misen place d’un système de management de la sécurité des denrées alimentaires </p>)
            },
            { 
                detail: "Duration", 
                description: "3 jours, Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
            },
            { 
                detail: "Dates des sessions :", 
                description: (<p> • Session 1 : 14-16/ 05 / 2025 <br/>• Session 2 : 24-26 / 09 / 2025</p>)
            },
            { 
                detail: "Price", 
                description: "900 DT(HT) /participant"
            }
        ]
    },
    {
        id: 'corporate-social-responsibility',
        title: 'Auditeur interne ISO 22000:2018',
        details: [
            { 
                detail: "Description", 
                description: "La norme ISO 22000 positionne l’audit interne comme un outil de management incontournable pour la vérification de l’efficacité des systèmes de management de la santé et de la sécurité au travail."
            },
            { 
                detail: "Objectives", 
                description: (<p> •Acquérir la qualification « auditeur interne ISO 22000:2018 » <br/>• Maitriser les techniques d’audit et de communication en situation d’audit. <br/>• Etre capable de réaliser des audits internes à haute valeur ajoutée. <br/> • Savoir rédiger un rapport d’audit basé sur l’amélioration.  </p>)
            },
            { 
                detail: "Duration", 
                description: "3 jours, Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
            },
            { 
                detail: "Dates des sessions :", 
                description: (<p>• Session 1 : 04-06 / 06 / 2025 <br/>• Session 2 : 12-14 / 11 / 2025</p>)
            },
            { 
                detail: "Price", 
                description: "900 DT(HT) /participant"
            }
        ]
    },
    {
        id: 'fssc-22000-v6',
        title: 'Exigences de la Norme FSSC 22000 V6',
        details: [
            { 
                detail: "Description", 
                description: "La certification d’un système de management de la sécurité des denrées alimentaires selon la norme FSSC 22000 démontre un engagement envers la sécurité des aliments, la qualité, la légalité et l'amélioration continue."
            },
            { 
                detail: "Objectives", 
                description: (
                    <p>
                        • Situer la norme FSSC 22000 dans le contexte normatif et règlementaire <br/>
                        • Comprendre la structure et les exigences de la norme FSSC 22000 v6 / être en mesure de mettre en place un système de management de la sécurité des denrées alimentaires conforme aux exigences FSSC 22000 v6 <br/>
                        • Mise en évidence des modifications : version 6 vs version 5.1
                    </p>
                )
            },
            { 
                detail: "Duration", 
                description: "3 jours, Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
            },
            { 
                detail: "Dates des sessions :", 
                description: (<p>• 07-09 / 05 / 2025</p>)
            },
            { 
                detail: "Price", 
                description: "950 DT(HT) /participant"
            }
        ]
    }, 
    {
        id: 'haccp-method',
        title: 'Méthode HACCP : Garantir la sécurité des aliments',
        details: [
            { 
                detail: "Description", 
                description: "La méthode HACCP a été créée dans le but de garantir la sécurité des aliments. Elle prévoit l'analyse des dangers à chaque étape de la production en vue de leur maîtrise."
            },
            { 
                detail: "Objectives", 
                description: (
                    <p>
                        • Positionner la démarche HACCP dans le cadre d’un système de management de la sécurité des denrées alimentaires <br/>
                        • Assimiler les principes et les étapes de la méthode HACCP <br/>
                        • Être en mesure de réaliser l’analyse des dangers liés à la sécurité alimentaire et d’adopter les mesures et les bonnes pratiques d'hygiène
                    </p>
                )
            },
            { 
                detail: "Duration", 
                description: "3 jours, Horaire de la formation: de 08h30 à 16h00 (pauses incluses)"
            },
            { 
                detail: "Dates des sessions :", 
                description: (<p>• 23-25 / 06 / 2025</p>)
            },
            { 
                detail: "Price", 
                description: "950 DT(HT) /participant"
            }
        ]
    }
    
];

const SécuritéAlimentaire = () => {
    const [selectedTraining, setSelectedTraining] = useState(null);

    // Function to handle detail view
    const handleDetailsView = (training) => {
        setSelectedTraining(training);
    };

    // Function to go back to the catalog
    const handleBackToCatalog = () => {
        setSelectedTraining(null);
    };

    // Render the catalog view
    const renderCatalog = () => (
        <div className="training-catalog">
            <h1 className="catalog-title">Formation - Sécurité Alimentaire</h1>
            <div className="training-list">
                {trainings.map((training) => (
                    <div key={training.id} className="card-centered">
                        <h3 className="card-title">{training.title}</h3>
                        <p className="card-description">{training.description}</p>
                        <button onClick={() => handleDetailsView(training)} className="btn">
                            En savoir plus
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );

    // Render the details view
    const renderDetails = () => (
        <div className="container mt-5">
            <button className="btn btn-secondary mb-4" onClick={handleBackToCatalog}>
            Retour au Catalogue
            </button>
            <table className="details-table">
                <thead>
                    <tr>
                        <th colSpan="2" className="details-title">{selectedTraining.title}</th>
                    </tr>
                    <tr>
                        <th>Detail</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {selectedTraining.details.map((detail, index) => (
                        <tr key={index}>
                            <td>{detail.detail}</td>
                            <td>{detail.description}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <div className="min-vh-100">
            <Header /> {/* Include the Header */}
            <div className="catalog-spacing" /> {/* Add spacing below the header */}
            {selectedTraining ? renderDetails() : renderCatalog()}
            <Footer /> {/* Include the Footer */}
        </div>
    );
};

export default SécuritéAlimentaire ;
