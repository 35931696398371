import React, { Component } from 'react';
 // Single mission image for the mission section
import valuesImage from '../Images/values.jpg'; // Single image for "Nos Valeurs"
import './About.css';
// Icons and descriptions for the values section
const values = [
    {
        "icon": "fa-check-circle",
        "title": "L'intégrité",
        "description": "L'intégrité est la valeur fondamentale de notre entreprise. Notre éthique professionnelle consiste à honorer en permanence nos engagements avec nos clients et nos partenaires, à agir toujours et dans toutes les circonstances avec honnêteté, transparence et courage et à rejeter toute forme de corruption."
    },
    {
        "icon": "fa-handshake",
        "title": "Le respect",
        "description": "Nous traitons nos clients et nos partenaires avec considération. Nous œuvrons à créer un environnement optimal pour l'épanouissement et le développement personnel de nos collaborateurs. Nous respectons la diversité de pensée, d'expérience et de culture. Nous participons activement à la lutte contre les discriminations et l'inégalité des chances."
    },
    {
        "icon": "fa-star",
        "title": "L’excellence",
        "description": "La poursuite de l'excellence est un élément indissociable de la culture de notre entreprise. Nous visons les plus hauts standards de qualité et de performance. Nous nous efforçons sans cesse d'améliorer l’expérience client à travers l'amélioration continue de nos solutions, de nos pratiques et de nos méthodes."
    },
    {
        "icon": "fa-lightbulb",
        "title": "L'innovation",
        "description": "Nous anticipons les besoins de nos clients et les changements qui s'opèrent autour de nous. Nous cultivons notre ouverture d'esprit aux nouvelles idées et à la prise d'initiative. Nous encourageons nos collaborateurs à repousser les limites de la connaissance et à concevoir des solutions créatives et innovantes permettant d'améliorer durablement la performance de nos clients et de leur procurer des avantages stratégiques."
    },
    {
        "icon": "fa-leaf",
        "title": "La durabilité",
        "description": "Assurer le développement durable de nos communautés et de nos économies est un impératif primordial. Nous voulons avoir un impact positif et durable sur notre entourage aussi bien à travers notre travail quotidien auprès de nos clients que par des projets bénévoles."
    }
];

class Services extends Component {
    render() {
        return (
            <div className="container-xxl py-5">
                <div className="container">
                    {/* Enhanced Mission Section */}
                    <div className="mission-section text-center mt-5">
                        
                        <h3 className="mb-4">Notre Mission</h3>
                       
                        {/* Invisible Container for the Mission Paragraph */}
                        <div className="invisible-container">
                            <p className="styled-paragraph mb-4 ">
                                Nous mettons à disposition de nos clients de tous les secteurs le savoir-faire de pointe et les meilleures pratiques pour les aider à accéder aux meilleures performances et à développer des avantages concurrentiels durables dans un environnement économique toujours plus complexe et contraignant.
                            </p>
                        </div>
                    </div>

                    {/* Section des valeurs */}
                    <div className="values-section text-center mb-5">
                        
                        <img 
                            src={valuesImage} 
                            alt="Nos Valeurs" 
                            className="values-image img-fluid mb-4"
                        />
                        <h3 className="mb-4">Nos Valeurs</h3>
                        <div className="row g-4 justify-content-center">
                            {values.map((v, index) => (
                                <div key={index} className="col-md-4">
                                    <div className="value-item d-flex flex-column align-items-center p-4">
                                        <i className={`fas ${v.icon} icon-value mb-2`}></i> {/* Add icon */}
                                        <h4>{v.title}</h4>
                                        <p>{v.description}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Services;
