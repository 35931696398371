import React, { Component } from 'react';
import './Service.css';  // Import the CSS file
import home from '../Images/home.jpg';  // Import the first image
import ecEnv from '../Images/ec-env.jpg';  // Import the second image

class Aboutsection extends Component {
    render() {
        return (
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-5 justify-content-center align-items-center">
                        {/* First Image Section */}
                        <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
                            <img 
                                src={home}  // First image
                                alt="Lean Management Institute"
                                className="img-fluid rounded shadow-lg equal-size-img"  // Apply custom class for equal sizing
                            />
                        </div>

                        {/* Second Image Section */}
                        <div className="col-lg-6 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
                            <img 
                                src={ecEnv}  // Second image
                                alt="Environmental Transformation"
                                className="img-fluid rounded shadow-lg equal-size-img"  // Apply custom class for equal sizing
                            />
                        </div>

                        {/* Text Section */}
                        <div className="col-lg-7 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
                            <p className="styled-paragraph mb-4">
                                Lean Management Institute est un cabinet de formation et de conseil spécialisé dans l'excellence opérationnelle, 
                                le Lean Management, le Six Sigma, les méthodes agiles et la transformation organisationnelle dans une perspective 
                                d’amélioration efficace et durable des performances opérationnelles et stratégiques de l’entreprise.
                            </p>
                            <p className="styled-paragraph mb-4">
                                Nous vous proposons des formations de pointe et un conseil / accompagnement qui vous permettront - à travers le développement des compétences 
                                et le déploiement de projets d’amélioration à forte valeur ajoutée - d’améliorer significativement la performance, l’efficacité et la productivité 
                                de vos opérations et d'être ainsi en mesure de répondre à vos défis stratégiques.
                            </p>
                            <p className="styled-paragraph mb-4">
                                Nous mettons à votre disposition le savoir-faire et les méthodologies d'avant-garde qui font le succès des entreprises japonaises et américaines 
                                les plus performantes, tout en vous fournissant les outils pratiques et les méthodes qui vous permettent de déployer vos projets d’amélioration 
                                rapidement et efficacement.
                            </p>
                            <p className="styled-paragraph mb-4">
                                Nous vous proposons différents modes de formation (formations en inter-entreprises et formations en intra-entreprises) adaptés à vos besoins et 
                                aux enjeux stratégiques de votre entreprise, ainsi qu'une assistance et/ou un coaching individualisé pour vous accompagner lors de vos projets.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Aboutsection;
