import React, { Component } from 'react';
import { Link } from 'react-router-dom'; // Import Link for internal routing
import './footer.css'; // Make sure your CSS file is loaded for custom styles

class Footer extends Component {
    render() {
        return (
            <div className="container-fluid bg-dark text-light footer pt-4 mt-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-4">
                    <div className="row g-4">
                        {/* Contact Section */}
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-white mb-3">Contact</h5>
                            <p className="mb-2"><i className="fa fa-map-marker-alt me-2" />Résidence Perle du Lac 1053, Les Berges du Lac II, Tunis</p>
                            <p className="mb-2"><i className="fa fa-phone-alt me-2" />(+216) 21 630 851</p>
                            <p className="mb-2"><i className="fa fa-envelope me-2" />contact@lmi.training</p>
                            <div className="d-flex pt-2">
                                <a className="btn btn-outline-light btn-social me-2" href="https://www.facebook.com/@Lean.Management.Institute" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f" /></a>
                                <a className="btn btn-outline-light btn-social" href="https://www.linkedin.com/company/lean-management-institute-training-and-consulting" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in" /></a>
                            </div>
                        </div>
                        
                        {/* Quick Links Section */}
                        <div className="col-lg-3 col-md-6">
                            <ul className="list-unstyled">
                                <li><Link className="text-light" to="/catalogue"><i className="fas fa-th-list me-2" /> Formations</Link></li>
                                <li><Link className="text-light" to="/contact"><i className="fas fa-phone-alt me-2" /> Télécharger programme formations </Link></li>
                                <li><Link className="text-light" to="/certibase"><i className="fas fa-certificate me-2" /> Certibase</Link></li>
                                <li><a className="text-light" href="pdfs/LMI-FORMATIONS-2024" download><i className="fas fa-download me-2" /> Contact </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Footer;
